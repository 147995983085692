html,
body {
  overflow-x: hidden;
}
.about {
  padding: 30px 0;
  text-align: center;
}
.about h1{
  color: #031466;
  font-family: 'arial';
  margin-bottom: 70px;
}

.cards__container{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;

}

.about__card{
  width: 480px;
  display: flex;
  align-items: start;
  margin-bottom: 30px;
  padding: 0 10px;

}
.about__card img{
  width: 220px;
  height: 250px;
  /* border: 2px solid blue;
  border-radius: 10px; */

}
.about__card__right{
  text-align: start;
  margin-left: 20px;
}
.about__card__right h3{
  color: #041E60;
  font-size: 24px;
}
.about__card__right div{
  margin: 10px 0;
  color: #747474;
}
.about__card__right p{
  line-height: 26px;
  color: #292929;
  font-size: 14px;
}

@media only screen and (max-width: 600px){
  .about__card img{
    width: 100px;
    height: 100px;
  }
  .about__card__right{
    margin: 0 10px;
  }

}