.privacy__content {
    max-width: 1000px; /* Limit width for better readability */
    margin: 40px auto; /* Center the content with top/bottom margin */
    padding: 150px 30px;
    background-color: #ffffff; /* White background for the content */
   
}

.title-class {
    font-size: 2.8em; /* Larger title size */
    color: #2c3e50; /* Darker color for the title */
    text-align: center;
    margin-bottom: 20px;
}

.effective-date-class {
    font-size: 1.1em;
    color: #7f8c8d; /* Softer gray for the date */
    text-align: center;
    margin-bottom: 40px;
}

.main-class {
    line-height: 1.7; /* Increased line height for better readability */
}

.section-class {
    margin-bottom: 35px; /* Space between sections */
}

.subheading-class {
    font-size: 2em; /* Slightly smaller than title but prominent */
    color: #2980b9; /* Blue color for subheadings */
    border-bottom: 2px solid #2980b9; /* Underline effect */
    padding-bottom: 5px; /* Space between text and underline */
}

.content-class {
    font-size: 1em;
    color: #444; /* Dark gray for content text */
}

.email-link-class {
    color: #e74c3c; /* Red color for email link */
    text-decoration: none;
}

.email-link-class:hover {
    text-decoration: underline;
}