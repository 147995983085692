.bookPage{
    /* overflow-x: auto; */
    
    
}

/* src/ImageSlider.css */
body .bookPage {
    background-color: #028F96 !important;
    margin: 0;
    height: 100vh; /* Full viewport height */
    overflow-y: scroll; /* Hide body scrollbar */
    overflow-x: hidden;
}

.slider-container {
    height: 100%; /* Full height of body */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px; /* Optional padding */
    box-sizing: border-box; /* Include padding in height calculation */
    
    margin: 50px auto;
    width: 40vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: relative; */
    height: 100vh; /* Set height to full viewport height */
    overflow: hidden; /* Hide overflow to prevent scrollbars */
}

.slider-image {
    
    width: 100%; /* Allow width to adjust automatically */
    height: 80%; /* Set height to a percentage of the container */
    max-height: 80vh; /* Ensure max height does not exceed viewport height */
}

@media only screen and (max-width: 768px){
    .slider-image{
        height: 60%;
        
    }
}


.arrow {
    margin: 0 20px !important;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: white !important; /* Change arrow color for better visibility */
}

.arrow.left {
    /* position: absolute;
    left: 0px; */
    /* color: blue !important; */
    font-size: 40px !important;

  
}
.hhh{
    min-width: 90vw;
    margin: 0 auto;
}


.arrow.right {
    /* position: absolute;
    right: 0px; */
    /* color: blue !important; */
    font-size: 40px !important;
}

@media only screen and (max-width: 1200px){
    .slider-container{
        min-width: 50vw;
        margin-top: 40px !important;
    }
    .slider-image {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px){
    .slider-container{
        min-width: 95vw;
        margin-top: 40px !important;
    }
    .slider-image {
        width: 100%;
    }
    .book__ul a li{
        margin: 0 3px;
        
    }

}

@media only screen and (max-width: 500px){
    .slider-container{
        min-width: 95vw;
        margin-top: 40px !important;
    }
    .slider-image {
        width: 85%;
    }
    .book__ul a li{
        margin: 0 3px;
        
    }

}