.plan {
  text-align: center;
  padding: 60px 0;
}
.plans__container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.plan__title {
  color: #04435e;
  font-size: 20px;
  margin: 10px 0;
  font-family: "arial";
}

.plan__content {
  width: 300px;
  text-align: center;
  margin: 20px;
  border: 1.5px solid black;
}
.plan__content img {
  width: 100%;
  height: 250px;
}
.icon__text {
  display: flex;
  align-items: center;
  /* background-color: red; */
  /* width: 35%; */
}
.plan__icon {
  margin-right: 5px;
}

.icon__text__container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 10px 0;
}

.buy__button {
  background: none;
  color: #04435e;
  padding: 5px 15px;
  border: 2px solid #04435e;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-family: "arial";
  font-weight: bold;
  margin: 10px;
}
.openBook {
  background-color: #abdcf6;
  color: #04435e;
  padding: 5px 15px;
  border: 2px solid #04435e;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-family: "arial";
  font-weight: bold;
  margin: 10px;
}


.form{
  text-align: start;
  margin: 30px 100px;
  height: 200px;
  width: fit-content;
}
.form div{
  color: #04435e;
  font-size: 18px;
}
.form input{
  margin: 15px 0;
  padding: 5px 10px;
  background: none;
  border: 2px solid #04435e;
  font-size: 18px;
  width: 300px;
  outline: none;
  border-radius: 3px;

}
.form button{
  color: #04435e;
  background-color: #abdcf6;
  padding: 3px 8px;
  border-radius: 3px;
  /* border: none; */
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid gray;
}

