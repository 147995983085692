html {
  scroll-behavior: smooth;
}
#header {
  width: 100%;
  position: fixed;
  top: 0; /* Initially hide the header */

  transition: top 0.3s; /* Animation for sliding effect */
  z-index: 1000; /* Ensure it stays on top */
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  margin: 0px 0 70px 0;
  padding: 20px 100px 20px 100px;
  

  /* position: absolute;
    top: 20px; */

  color: white;
  /* padding-top: 20px; */
  /* margin: 0 100px; */
}

a {
  text-decoration: none !important;
}
#header ul {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 500px;
  /* background-color: red; */
  margin-bottom: -5px;

  /* width: 600px; */
}
#header ul a{
  margin: 0 5px;
}
#header ul a li {
  list-style: none;
  font-family: "arial";
  color: rgb(224, 224, 224) !important;
  cursor: pointer;
}
#header ul a li:hover {
  color: rgb(204, 204, 204);
}

#header > img {
  width: 110px;
  /* transform: scale(0.08); */
  /* max-width: 110px !important; */
}

@media only screen and (max-width: 800px) {
  #header {
    padding: 0;
  }
  #header ul {
    width: 60vw;
    margin-right: -60px;
  }
  #header a img {
    width: 60px;
    margin-left: -80px;
  }
}

@media only screen and (max-width: 500px) {
  #header a img{
    margin-left: -80px;
  }
  #header ul{
    display: flex;
    align-items: center;
    
    justify-content: space-between;
  }
  #header ul a li{
    /* line-height: 70px; */
  }
}