.whyUs{
    background-color: rgb(245, 249, 255);
    padding: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
}
.whyus__left{
    width: 500px;
    font-family: 'arial';
    padding: 0 20px;
    margin-bottom: 20px;
}

.whyus__left h1{
    font-size: 40px;
    color: #253E56;
}
.firstPara{
    font-size: 20px;
    line-height: 1.4;
    margin: 20px 0;
    color: rgb(76, 90, 112);
}
.whyus__benefit{
    display: flex;
    align-items: center;
    margin: 5px -5px 5px 0;
    
}
.whyus__icon{
    font-size: 30px !important;
    margin-left: 10px !important;
    color: rgb(51, 130, 209) !important;
}
.enrollbtn{
    background-color: #54A4E4;
    padding: 10px 35px;
    border-radius: 10px;
    cursor: pointer;
    color:white;
    border: none;
    outline: none;
    margin: 20px 0 0 10px;
    font-size: 20px;
}
.enrollbtn:hover{
    background-color: #63ace7;
}


.whyus__right{
    width: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    

}
.whyus__right div{
    width: 280px;
    height: 280px;
    margin: 10px;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
    
}
.whyus__right>div>img{
    width: 100%;
    height: 100%;
    transition: transform 1s;
}
.whyus__right>div>img:hover{
    transform: scale(1.2);

}

.whyus__benefit p{
    margin: 10px 0;
    font-size: 20px;
    color: rgb(76, 90, 112);
    
}
