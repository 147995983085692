.opinions {
  text-align: center;
  /* background-color: rgb(0, 0, 107); */
  padding: 60px 0;
}
.opinions h1 {
  color: white;
  margin-bottom: 50px;
}

.carousel__container {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto 40px auto;
}
.carousel__container img {
  width: 400px;
  margin: 20px 0;
}

.about__course,
.about__book {
  width: 400px;
  padding: 30px 0;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  /* margin: 0 auto 40px auto; */
}
.about__course img,
.about__book img{
    width: 100%;
}

.opinions__video {
  width: 60vw;
}

@media only screen and ( max-width: 767px ) {
  .opinions__video{
    width: 80vw;
  }
}
