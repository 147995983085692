.free__vocabulary__container {
  padding: 100px 0;
  width: 100vw;
  text-align: center !important;
}
.exercice1,
.exercice2,
.exercice3 {
  text-align: start;
  margin: 100px 70px;
  width: 90vw;
}
.exercice3 {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 20px 10px 20px 30px; */
  justify-content: space-evenly;
}

.exercice1 p,
.exercice2 p,
.exercice3 p {
  font-size: 20px;
  font-weight: 800;
  color: rgb(0, 0, 85);
  margin-left: 30px;
}
.exercice1 button,
.exercice2 button {
  border: 1px solid gray;
  padding: 5px 15px;
  margin: 10px 10px 10px 30px;
}

.exercice1 ol li, .exercice2 ol li {
  margin: 20px 0;
}
.exercice1 ol li input,.exercice2 ol li input {
  outline: none;
  width: 110px;
  /* background-color: red; */
  border: 1px solid gray;
}

.iframe {
  width: 50vw;
  height: 60vh;
  margin: 0 auto;
  background-color: blue;
}

.exercice1 ol li,
.exercice2 ol li {
  list-style: none !important;
  /* background-color: red; */
}
.exercice2 ol {
}
.exercice1 ol li b,.exercice2 ol li b {
  color: red;
}
.iframe iframe {
  width: 100%;
  height: 100%;
}

.checkbox__options {
  /* background-color: red; */
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  flex-wrap: wrap;
  /* background-color: red; */
}
.option {
  display: flex;
  align-items: center;
  width: 100px;
  margin: 10px;
  /* background-color: blue; */
}
.option input {
  margin-right: 5px;
}

#checkbox1:checked {
  background: green !important;
  color: white;
}

.exercice3__left__right ul {
  list-style-type: upper-alpha;
  
}


.ex3__answers__bottom {
  color: green;
  font-size: 18px;
  display: none;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.ex3__answers__container span input {
  width: 30px;
  outline: none;
  height: 20px;
}

@media only screen and (max-width: 600px) {
  .checkbox__options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: start;
    /* background-color: purple; */
  }
  .option {
    /* background-color: yellow; */
    text-align: left;
  }
}

@media only screen and (max-width: 500px) {
  .exercice1,
  .exercice2,
  .exercice3 {
    width: 95vw;
    margin-left: 0px;
    /* float: left; */
    /* background-color: purple; */
  }
}

.ex3__answers__top span {
  margin-right: 5px;
  /* background-color: red; */
  width: 60px;
}

@media only screen and (max-width: 620px) {
  .ex3__answers__top {
    display: flex;
    /* flex-direction: column; */
    justify-content: flex-start;
  }
  
 
  .exercice3 {
    justify-content: flex-start !important;
  }
}
@media only screen and (max-width: 500px) {
  .exercice3 {
    margin: 0 auto;
    width: 90vw;
    /* background-color: red; */
    justify-content: flex-start !important;

    /* padding: 0 10px; */
  }
  .ex3__collocations {
    /* background-color: blue; */
    justify-content: space-evenly;
  }
}

.ex3__collocations{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.ex3__answers__container{
  padding: 0 30px;
}

.exercice3 button{
  border: 1px solid gray;
  padding: 5px 15px;
  margin: 10px 10px 10px 30px;
}

.ex3__answers__top{
  margin: 10px 0;
}