

.bookLogin {
  background: linear-gradient(#028F96, #0B6D72);
  height: 100vh !important;
  width: 100vw !important;
  position: relative;
}
.book__header{
  /* background: linear-gradient(#028F96, #0B6D72) !important; */
  background: transparent !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  background: none !important;
}

.bookLogin__form {
    /* background-color: #dcfff9; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 30vw;
  position: absolute;
  top: 30%;
  left: 35% !important;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.bookLogin__form * {
  width: 25vw;
}
.bookLogin__form img {
  width: 100px;
  margin: 5px 0;
}

.bookLogin__form p {
  font-size: 14px;
  color: gray;
  text-align: center;
}
.bookLogin__form input {
  margin: 15px 0;
  padding: 5px 10px;
  background: none;
  border: 2px solid #028F96;
  font-size: 18px;
  width: 100%;
  outline: none;
  border-radius: 5px;
}
.bookLogin__form button {
  border-radius: 5px;
  color: white;
  font-size: 18px;
  background: linear-gradient(#028F96, #0B6D72);

  padding: 5px 10px;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 5px 0;
}


@media only screen and (max-width: 1000px) {
  .bookLogin__form {
    min-width: 50vw !important;
    left: 25vw !important;
    /* margin-top: -30px; */
  }
  .bookLogin__form p {
    width: 95%;
  }
}
@media only screen and (max-width: 600px) {
  .bookLogin__form {
    min-width: 60vw !important;
    position: absolute !important;;
    left: 20vw !important;
    margin-top: -40px;
  }
  .bookLogin__form p {
    width: 95%;
  }
}

.username,
.password {
  margin: 3px 0;
  display: none;
  color: red !important;
  font-size: 14px;
}



body{
  background-color: rgba(238, 226, 226, 0.336);
}
#book__header{
  width: 100%;
  position: fixed;
  top: 0; /* Initially hide the.allCourses  header */

  transition: top 0.3s; /* Animation for sliding effect */
  z-index: 1000; /* Ensure it stays on top */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0px 0 30px 0; */
  padding: 10px 125px 10px 125px;

  /* background-color: #f1f1f1; */

  /* position: absolute;
    top: 20px; */

  /* padding-top: 20px; */
  /* margin: 0 100px; */
}
#book__header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50vw;
  margin-left: -20px;
  /* background-color: red; */
  margin-bottom: -5px;

  /* width: 600px; */
}
#book__header ul a{
  margin: 0 5px;
}
#book__header ul a li {
  list-style: none;
  font-family: "arial";
  cursor: pointer;
  color: #253E56 !important;
  font-size: 18px;
}
#book__header ul li:hover {
}
#book__header > img {
  /* transform: scale(0.08); */
  width: 60px;
}

@media only screen and (max-width: 800px) {
  #book__header {
    /* padding: 0; */
  }
  #book__header ul {
    width: 60vw;
    margin-right: 0px;
  }
  #book__header > img {
    width: 60px;
    margin-left: 0px;
  }
}


html {
  scroll-behavior: smooth;
}
#bookHeader {
  width: 100%;
  position: fixed;
  top: 0; /* Initially hide the header */

  transition: top 0.3s; /* Animation for sliding effect */
  z-index: 1000; /* Ensure it stays on top */
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  margin: 0px 0 70px 0;
  padding: 20px 100px 20px 100px;
  

  /* position: absolute;
    top: 20px; */

  color: white;
  /* padding-top: 20px; */
  /* margin: 0 100px; */
}

a {
  text-decoration: none !important;
}
#bookHeader ul {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  width: 50vw !important;
  /* background-color: red; */
  margin-bottom: -5px;

  /* width: 600px; */
}
#bookHeader ul a li {
  list-style: none;
  font-family: "arial";
  color: rgb(224, 224, 224) !important;
  cursor: pointer;
}
#bookHeader ul a li:hover {
  color: rgb(204, 204, 204);
}

#bookHeader > img {
  /* transform: scale(0.08); */
  width: 110px;
}

@media only screen and (max-width: 800px) {
  #bookHeader {
    padding: 10px 20px;
  }
  #bookHeader ul {
    width: 60vw;
    margin-right: 0px;
  }
  #bookHeader > img {
    width: 60px;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 500px) {
  #bookHeader a img {
    width: 60px;
    margin-left: 0px;
  }
  #bookHeader ul {
    display: flex;
    align-items: center;
    margin-right: 30px;
    justify-content: space-around;
  }
  #bookHeader ul a{
    margin: 0 5px !important;
    /* line-height: 70px; */
  }
}

