.footer{
    padding: 50px 10px 20px 10px;
    background-color: #253E56;
}
.footer__container{
    display: flex;
    align-items: top;
    justify-content: space-around;
    flex-wrap: wrap;

}

.footer__left *,.footer__center *,.footer__right {
    width: 300px;
    text-align: start !important;
    color: white;
    
}
.footer__right *{
    text-align: start !important;
}
.footer__center h2{
    font-size: 22px;
    font-family: 'lora';
    /* margin: 30px 0; */
}
.footer__left h2{
    font-family: 'lora';
    color: white;
    font-size: 22px;

}
.footer__left p{
    
    margin-top: 5px;
    line-height: 30px;
    font-size: 12px;
    /* font-style: italic; */
    
}
.footer__center{
    margin: 10px 0 30px 0;
    
}
.terms div,.privacy div{
    font-size: 14px !important;
    text-decoration: none !important;
}
.terms div,.privacy div{
    margin: 5px 0 !important;
    font-size: 18px;
  
}

.footer__right h2{
    font-family: 'lora';    
    color: white;
    font-size: 22px;
   
}
.social__icons{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    margin: 15px 0;
}
.footer__icon{
    color: #031466 !important;
    background-color: white !important;
    border-radius: 50%;
    padding: 3px !important;
    margin: 0 5px;

}


.copyright{
    text-align: end;
    margin-top: 10px;
    color: white;
    font-size: 16px;

}