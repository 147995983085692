body {
  background-color: rgba(238, 226, 226, 0.336);
}
#free__header {
  width: 100%;
  position: fixed;
  top: 0; /* Initially hide the.allCourses  header */

  transition: top 0.3s; /* Animation for sliding effect */
  z-index: 1000; /* Ensure it stays on top */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0px 0 30px 0; */
  padding: 10px 125px 10px 125px;

  background-color: #f1f1f1;

  /* position: absolute;
    top: 20px; */

  /* padding-top: 20px; */
  /* margin: 0 100px; */
}
#free__header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  /* background-color: red; */
  margin-bottom: -5px;

  /* width: 600px; */
}
#free__header ul a li {
  list-style: none;
  font-family: "arial";
  cursor: pointer;
  color: #253e56 !important;
  font-size: 18px;
}
#free__header ul li:hover {
}
#free__header > img {
  /* transform: scale(0.08); */
  width: 60px;
}

@media only screen and (max-width: 800px) {
  #free__header {
    /* padding: 0; */
  }
  #free__header ul {
    width: 60vw;
    margin-right: -80px;
  }
  #free__header > img {
    width: 60px;
    margin-left: -80px;
  }
}

.courses__container {
  padding: 100px 0 50px 0;
}
.free__course {
  width: 100vw;
  padding: 10px 0 100px 0;
}
.free__course h2 {
  text-align: center;
  width: 80vw;
  padding: 10px 0;
  margin: 0 auto 80px auto;
  background-color: #253e56;
  /* border: 1px solid #253E56; */
  color: white;
  font-size: 24px;
  border-radius: 5px;
}
.introduction__p {
  text-align: center;
  max-width: 80vw;

  /* padding: 10px 0; */
  color: #031466;
  /* font-weight: bold; */
  margin: -30px auto 20px auto;
}
.img__title__container {
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px auto 0 auto;

  /* background-color: red; */
}
.course__img__title {
  padding: 10px;
  text-align: center;
  width: 300px;

  /* background-color: red; */
  /* padding: 10px; */
  margin: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 12px 2px rgba(128, 128, 128, 0.5);
  position: relative;

  /* background-color: red; */
}

.course__img__title div {
  /* color: black; */
  font-weight: bold;
  font-size: 18px;
  /* padding: 0 10px; */
  text-align: left;
}
.courseTitle {
  margin-bottom: -10px;
  color: #00105f;
}
.course__img__title p {
  padding: 0 20px;
}

.course__img__title img {
  width: 100%;
  /* padding: 20px; */
  margin-bottom: 10px;
  border-radius: 5px;

  background-color: red;
}

.arabic__p {
  direction: rtl;
  width: 300px !important;
  text-align: right !important;
  font-weight: bold;
  margin-top: 20px;
  color: #00105f;

  /* background-color: red; */
}
.vocabulary__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 !important;
  /* background-color: red; */
}
.vocabulary__buttons .moreInfo {
  padding: 5px 10px;
  background-color: white;
  color: #031466;
  border: 1px solid #031466;
  border-radius: 5px;
  font-weight: 600;
}
.vocabulary__buttons .allCoursesGetStarted:hover {
  background-color: green;
  color: white;
  border: 1px solid green;
}
.vocabulary__buttons .allCoursesGetStarted {
  background: rgb(0, 0, 83);
  color: white;
  padding: 5px 10px;
  border: 1px solid #031466;
  border-radius: 5px;
  cursor: pointer;
  /* margin-bottom: 0px; */
  /* font-size: 18px; */
}

@media only screen and (max-width: 1200px) {
  .img__title__container {
    justify-content: center;
  }
}

.video__section {
  position: absolute;
  top: 20%;
  left: 25%;

  max-width: 50vw;
  max-height: 60vh;
}
.video__section video {
  width: 100%;
}
.freeIntro__iframe {
  height: 100%;
  width: 100%;
  /* background-color: red; */
}


@media only screen and (max-width: 768px) {
  .video__section {
    position: absolute;
    top: 20%;
    left: 5%;
  
    max-width: 90vw;
    max-height: 80vh;
  }
  #free__header img{
    margin-left: -80px;
  }
  
}

