.feedback {
  /* background-color: #f0f4ff; */
  background-color: #f3f5fa;

  
  padding: 100px 10px;
  text-align: center;

}


.feedbackImg,.feedbackBook img,.feedbackCourse img{
  width: 400px;
  
}


.book__container,.course__container{
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.book__course {
  margin: 70px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.book__container h2,.course__container h2{
  color: rgb(0, 0, 100);
  font-size: 22px;
  margin: 0 0 40px 0;
}

.feedbackBook,
.feedbackCourse {
  margin: 0 20px;
  height: 400px;

  display: flex;
  align-items: center;
  /* background-color: red; */
  background-color: white;
  justify-content: space-around;
  box-shadow: 0 4px 12px 2px rgba(128, 128, 128, 0.5);
  border-radius: 10px;
  padding: 10px;
}

.backArrow,
.nextArrow {
  cursor: pointer !important;
  /* color: white !important; */
  font-size: 40px !important;
}
.star {
  color: yellow;
}

.feedback__video {
  width: 600px;
  height: 350px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px 2px rgba(128, 128, 128, 0.5);
}
.feedbackBook img,.feedbackCourse img{
    /* margin-bottom: -100px; */
    object-fit: cover;
    /* filter: brightness(70%); */
    clip-path: inset(0 0 0 10px); /* top right bottom left */
    
    
  }
  .feedbackTitle{
    text-align: center;
    color: #253E56;
    
  }

@media only screen and (max-width: 550px) {
  .feedbackImg{
    width: 300px;
    margin-bottom: -60px;
  }
  .book__container{
    margin-bottom: 10px;
  }
  .feedbackBook,
  .feedbackCourse {
    transform: scale(0.7);

  }
  .feedbackBook img,
  .feedbackCourse img {
    transform: scale(0.9);
  }
  .book__container h2,.course__container h2{
    margin-bottom: -20px;
  }
}

@media only screen and (max-width: 450px) {
  .feedbackBook,
  .feedbackCourse {
    transform: scale(0.7);
    margin: -20px 0;
  }
  .feedbackBook img,
  .feedbackCourse img {
    transform: scale(0.9);
  }
  .feedback__video {
    width: 330px;
    height: 200px;
    margin-top: -20px;
  }
}
