/* Main container styling */
.pricing {
    text-align: center;
    padding: 100px 20px;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .pricing h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #4a90e2; /* A calming blue color */
  }
  
  /* Pricing cards container */
  .pricing__container {
    display: flex;
    justify-content: center;
    gap: 20px; /* Space between cards */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
  }
  
  /* Individual card styling */
  .pricing__card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px; /* Fixed width for uniformity */
    display: flex; /* Enable flexbox layout */
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Space between items */
    height: 400px; /* Set a fixed height for uniformity */
    transition: transform 0.3s, box-shadow 0.3s; /* Animation effects */
  }
  
  .pricing__card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
  }
  
  .pricing__card h3 {
    font-size: 1.8rem;
  }
  
  .pricing__card h5 {
    font-size: 1.5rem;
    color: #e74c3c; /* A vibrant red for pricing */
  }
  
  .pricing__card del {
    color: #999; /* Light grey for old price */
  }
  
  /* Description paragraph styling */
  .pricing__card p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  /* Button styling */
  .pricing__card button {
    background-color: #4a90e2; /* Same blue as the header */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
  }
  
  .pricing__card button:hover {
     background-color: #357ab8; /* Darker shade on hover */
     transition: background-color .3s ease; /* Smooth transition */
  }
  
  /* Special styling for the annual card to make it stand out */
  .pricing__card.annual {
     border: solid #e67e22; /* Orange border for emphasis */
     border-width: thick; /* Thicker border for distinction */
     position: relative; /* Positioning for pseudo-element */
  }
  
  .pricing__card.annual::before {
     content: "الأكثر شعبية"; /* Tag for popularity */
     position: absolute;
     top: -15px; 
     left: -15px; 
     background-color: #e67e22; 
     color: white; 
     padding: .5em .75em; 
     border-radius: .5em; 
     font-weight: bold; 
  }