.login {
  width: 100vw;
  height: 100vh;
  /* max-height: 100vh; */
  background-color: #253e56;
  z-index: 1;
  padding: 100px 0 0 0;
  /* background-color: red; */
  overflow-y: scroll;
}
.login::-webkit-scrollbar {
  display: none !important;
}

/* General Styles */
/* body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
} */

.login__form {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 30vw;
  text-align: center;
  /* margin-top: 130px; */
  margin: 100px auto 0px auto;
}

#bookHeader {
  background-color: #253e56;
}
/* Typography */
.login__form p {
  color: #333333;
  margin-bottom: 1rem;
}

.login__form img {
  max-width: 30%;
  height: auto;
  margin-bottom: 1.5rem;
}

/* Input Fields */
.login__form input[type="text"] {
  width: 100%;
  padding: 5px 10px;
  margin: 15px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
}

.login__form input[type="text"]:focus {
  border-color: #007bff; /* Change border color on focus */
}

/* Error Messages */
.username,
.password {
  color: #e74c3c; /* Red for error messages */
  font-size: 0.9rem;
}

/* Button Styles */
.login__form button {
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  padding: 10px;
  margin: 15px 0;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease-in-out;
}

.login__form button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

body {
  background-color: rgba(238, 226, 226, 0.336);
}
#login__header {
  width: 100%;
  position: fixed;
  top: 0; /* Initially hide the.allCourses  header */

  transition: top 0.3s; /* Animation for sliding effect */
  z-index: 1000; /* Ensure it stays on top */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0px 0 30px 0; */
  padding: 10px 125px 10px 125px;

  background-color: #253e56;

  /* position: absolute;
    top: 20px; */

  /* padding-top: 20px; */
  /* margin: 0 100px; */
}
#login__header ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50vw;
  /* background-color: red; */
  margin-bottom: -5px;

  /* width: 600px; */
}
#login__header ul a li {
  list-style: none;
  font-family: "arial";
  cursor: pointer;
  color: white !important;
  font-size: 18px;
}
#login__header ul li:hover {
}
#login__header > img {
  /* transform: scale(0.08); */
  width: 60px;
}
#login__header > img {
  width: 60px;
}

@media only screen and (max-width: 800px) {
  #login__header {
    /* padding: 0; */
  }
  #login__header ul {
    width: 60vw;
    margin-right: -80px;
  }
  #login__header > img {
    width: 60px;
    /* margin-left: -80px; */
  }
  .login__form {
    width: 70vw;
  }
}

.courses__container {
  padding: 100px 0 50px 0;
}
.free__course {
  width: 100vw;
  padding: 10px 0 100px 0;
}
.free__course h2 {
  text-align: center;
  width: 80vw;
  padding: 10px 0;
  margin: 0 auto 80px auto;
  background-color: #253e56;
  /* border: 1px solid #253E56; */
  color: white;
  font-size: 24px;
  border-radius: 5px;
}
.introduction__p {
  text-align: center;
  max-width: 80vw;

  /* padding: 10px 0; */
  color: #031466;
  /* font-weight: bold; */
  margin: -30px auto 20px auto;
}
.img__title__container {
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px auto 0 auto;

  /* background-color: red; */
}
.course__img__title {
  padding: 10px;
  text-align: center;
  width: 300px;

  /* background-color: red; */
  /* padding: 10px; */
  margin: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 12px 2px rgba(128, 128, 128, 0.5);
  position: relative;

  /* background-color: red; */
}

.course__img__title div {
  /* color: black; */
  font-weight: bold;
  font-size: 18px;
  /* padding: 0 10px; */
  text-align: left;
}
.courseTitle {
  margin-bottom: -10px;
  color: #00105f;
}
.course__img__title p {
  padding: 0 20px;
}

.course__img__title img {
  width: 100%;
  /* padding: 20px; */
  margin-bottom: 10px;
  border-radius: 5px;

  background-color: red;
}

.arabic__p {
  direction: rtl;
  width: 300px !important;
  text-align: right !important;
  font-weight: bold;
  margin-top: 20px;
  color: #00105f;

  /* background-color: red; */
}
.vocabulary__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 !important;
  /* background-color: red; */
}
.vocabulary__buttons .moreInfo {
  padding: 5px 10px;
  background-color: white;
  color: #031466;
  border: 1px solid #031466;
  border-radius: 5px;
  font-weight: 600;
}
.vocabulary__buttons .allCoursesGetStarted:hover {
  background-color: green;
  color: white;
  border: 1px solid green;
}
.vocabulary__buttons .allCoursesGetStarted {
  background: rgb(0, 0, 83);
  color: white;
  padding: 5px 10px;
  border: 1px solid #031466;
  border-radius: 5px;
  cursor: pointer;
  /* margin-bottom: 0px; */
  /* font-size: 18px; */
}

@media only screen and (max-width: 1200px) {
  .img__title__container {
    justify-content: center;
  }
}

#bookHeader {
  width: 100%;
  position: fixed;
  top: 0; /* Initially hide the header */

  transition: top 0.3s; /* Animation for sliding effect */
  z-index: 1000; /* Ensure it stays on top */
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  margin: 0px 0 70px 0;
  padding: 20px 100px 20px 100px;

  /* position: absolute;
    top: 20px; */

  color: white;
  /* padding-top: 20px; */
  /* margin: 0 100px; */
}

a {
  text-decoration: none !important;
}
#bookHeader ul {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  max-width: 500px;
 
  
  /* background-color: red; */
  margin-bottom: -5px;

  /* width: 600px; */
}
#bookHeader ul a li {
  list-style: none;
  font-family: "arial";
  color: rgb(224, 224, 224) !important;
  cursor: pointer;
}
#bookHeader ul a li:hover {
  color: rgb(204, 204, 204);
}

#bookHeader >a> img {
  /* transform: scale(0.08); */
  width: 60px;
}

@media only screen and (max-width: 800px) {
  #bookHeader {
    padding: 10px;
  }
  #bookHeader ul {
    width: 50vw;
    /* margin-right: -60px; */
  }
  #bookHeader >a> img {
    width: 60px;
    /* margin-left: -80px; */
  }
}

@media only screen and (max-width: 500px) {
  #bookHeader a img {
    width: 60px;
    margin-left: 0px;
  }
  #bookHeader ul {
    display: flex;
    align-items: center;
    padding-right: 30px;
    justify-content: space-around;
  }
  #bookHeader ul a{
    margin: 0 5px !important;
    /* line-height: 70px; */
  }
}

#forgotPassword {
  margin-bottom: 10px !important;
}
