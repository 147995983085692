.courses{
    padding: 100px 0;
    font-family: 'arial';
}
.courses h1{
    text-align: center;
    margin: 0 0 50px 0;
    

}

.categories{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 50px 0;
}

.categories ul{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 600px;
    flex-wrap: wrap;
    /* background-color: red; */
    
    
}
.categories ul li{
list-style: none;
cursor: pointer;
margin:5px;
}

.courses__list{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}
.course{
    width: 310px;
    /* background-color: red; */
    padding: 10px;
    margin: 20px 10px;
    border-radius: 5px;
    box-shadow: 0 4px 12px 2px rgba(128, 128, 128, 0.5);
    position: relative;
}
/* .lockIcon{
    position: absolute;
    top: 65%;
    left: 46%;
    font-size: 34px !important;
    color: red;

    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    z-index: 1;
} */
.course img{
    width: 100%; 
    border-radius: 5px;
    
}

.course__title{
    color: rgb(0, 0, 83);
    font-weight: bold;
}
.freeTrialTitle{
    margin: 15px 0;
}

.lockIcon{
    color: red;
    font-size: 22px;
    margin-left: 10px;

}
.course__title__lock{

    display: flex;
    align-items: center;
    margin: 15px 0;
    
}

.icons__labels{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.icon__label{
    display: flex;
    align-items: center;
}
.icon{
    font-size: 16px !important;
    margin: 0 2px 0 0;
}

.icon__label span{
    font-size: 12px;
    margin-right: 10px;
}
.exams__book{
    margin-top: 50px !important;
}


.button__whatsapp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
}
.startFreeImg{
    border: 0.5px solid rgb(199, 199, 199) !important;
}
.button__whatsapp button{
    background: rgb(0, 0, 83);
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: 0.3s background-color ease-in-out;
}
.button__whatsapp button:hover{
    background-color: green;
}
.freeAccess {
    background-color: green !important;
    transition: 0.3s background-color ease-in-out !important;
}
.freeAccess:hover{
    /* background: rgb(0, 0, 83) !important; */
    
}

.whatsappIcon{
    font-size: 46px !important;
    color: rgb(0, 0, 83);
    
}