
.header-class {
    background: #007BFF;
    color: white;
    padding: 20px 0;
    text-align: center;
}
.title-class {
    margin: 0;
}

.effective-date-class {
    font-weight: bold;
}

.main-class {
    padding: 20px;
}

.section-class {
    background: white;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.subheading-class {
    color: #007BFF;
}

.content-class {
    margin: 10px 0;
}

.link-class {
    color: #007BFF; /* Link color */
}

.email-link-class {
    color: #007BFF; /* Email link color */
}
