.video__sections {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  /* background-color: red; */
  /* padding: 50px 130px; */
  flex-wrap: wrap;
  padding: 30px 0;
}
.video__sections video {
  min-width: 300px;
  height: 250px;
  
}
#video__sections__id{
  justify-content: center !important;
}
.tenses__page {
  background-color: #f1f1f1;
  padding: 50px 130px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}

.summary__button {
  width: 250px;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin: 30px auto;
}
.tenses__title {
  padding: 8px 130px;
  margin-top: 70px;
  background-color: #253e56;
  color: white;
  width: 100vw;
  font-size: 26px;
  text-align: center;
}
.video__title{
  margin-bottom: 20px;
}
.video__title div {
  color: #253e56;
  font-weight: bold;
  font-size: 18px;
}

.summary__button {
  min-width: 30vw;
  display: flex; /* Align items in a row */
  align-items: center;
  justify-content: center; /* Center items vertically */
  background-color: #4caf50; /* Green background */
  color: white; /* White text color */
  padding: 10px 20px; /* Padding around the content */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Bold text */
  text-transform: uppercase; /* Uppercase text */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transitions */
  cursor: pointer; /* Pointer cursor on hover */
}

.summary__button:hover {
  background-color: #45a049; /* Darker green on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.summary__button span {
  font-size: 14px;
  margin-right: 10px; /* Space between text and icon */
}

.summary__button svg {
  width: 24px; /* Set icon size */
  height: 24px; /* Set icon size */
}

@media only screen and (max-width: 1164px) {
  .video__sections {
    justify-content: center;
  }
}

@media only screen and (max-width: 964px) {
    .video__title {
      margin-bottom: 30px;
    }
  }

@media only screen and (max-width: 600px) {
  .tenses__title,.summary__button {
    font-size: 16px;
    min-width: 80vw !important;
    padding: 8px 0px !important;
  }
  .video__title {
    margin-bottom: 0px;
  }


}

