.hero {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./homepage123/HOMEPAGEcomp.jpg");
  
  height: fit-content !important;
  background-size: cover;
  
  
}



.hero__content {
  padding-top: 260px;
  padding-left: 100px;
  color: white;
  /* position: absolute;
  top: 260px;
  left: 100px; */
  width: 700px;
  margin-bottom: 70px;
}
.hero__content h3 {
  font-size: 22px;
  font-family: "arial";
  
}
.hero__content h1 {
  font-size: 64px;
  font-family: "arial";
  margin: -20px 0 10px 0;
}
.hero__content h1 span {
  border-bottom: 3px solid white;
}
.hero__content div {
  margin: 30px 0;
  font-size: 20px;
  font-family: "arial";
  color: rgb(214, 214, 214);
}

.getStarted {
  background-image: linear-gradient(
    -180deg,
    rgb(102, 186, 241) 0%,
    rgb(51, 130, 209) 96%
  );
  border-radius: 10px;
  white-space: nowrap;
  color: rgb(255, 255, 255);
  width: 150px;
  padding: 15px 35px;
  margin-right: 20px;
  margin-bottom: 20px;
  
  border: none;
  cursor: pointer;
}
.getStarted:hover {
  background-image: linear-gradient(
    -180deg,
    rgb(82, 182, 248) 0%,
    rgb(37, 110, 184) 96%
  );
}

.loginBtn {
  background-color: white;
  border-radius: 10px;
  white-space: nowrap;
  color: black;
  width: 150px;
  padding: 15px 35px;
  
  border: none;
  cursor: pointer;
}
.loginBtn:hover {
  background-color: rgb(187, 187, 187);
}


/* hero__bottom */
.hero__bottom{
  background-color: rgb(39, 60, 81,0.8);
  backdrop-filter: blur(8px);
  /* position: absolute;
  top: 700px; */
  color: white;
  font-family: 'arial';
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  padding: 10px 20px;
}


 .title__description{
  width: 200px;
  margin: 10px;
  
}
.title__description h1{
  font-size: 26px;
  margin-bottom: 10px;

}
.title__description p{
  /* word-break: break-all; */
  font-size: 15px;
  line-height: 24px;
  color: rgb(206, 206, 206);
}


iframe
{
  width: 400px;
  height: 200px;
  
  
}

@media (max-width: 1116px){
  iframe{
    flex-grow: 1;
    height: 500px;
  }

}
@media (max-width: 716px){
  iframe{
    flex-grow: 1;
    height: 200px;
  }

}
@media (max-width: 676px){
  iframe{
    flex-grow: 1;
    height: 300px;
  }
  

}

@media only screen and (max-width:800px){
  .hero__content{
    margin-top: -50px;
    margin-left: -80px;
    width: 100vw;
  }
  .hero__content h3{
    font-size: 18px;
  }
  .hero__content h1{
    font-size: 36px;
    
  }
  .getStarted{
    width: 120px;
    padding: 10px 20px;
    text-align: center;
  }
  .loginBtn{
    width: 120px;
    padding: 10px 20px;
    text-align: center;
  }
   
}