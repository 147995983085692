body {
 overflow-x: scroll;
}

.bookPdf{
  
  /* padding-top: 80px;  */
  width: 100vw;
  height: 100vh;
  clip-path: inset( 70px 0 0 0 );
  
}

.bookPdf__container{
  background: linear-gradient(to right, #1923B9,#101A84);  
  padding-top: 80px;
}

object{
  width: 100vw;
  height: 100vh;
}

@media only screen and (max-width: 700px) {
  object{
    width: 100px;
    height: 300px;
  }
}







